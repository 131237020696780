.modalContainer > div {
    margin-bottom: 10px;
}

.topBorderError {
    border-radius: 10px 10px 0px 0px;
    background-color: red;
    height: 12px;
}

.topBorderSuccess {
    border-radius: 10px 10px 0px 0px;
    background-color: green;
    height: 12px;
}

.icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.iconSuccess {
    border-radius: 50%;
    border: 2px solid green;
    height: 60px;
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.iconFail {
    border-radius: 50%;
    border: 2px solid red;
    height: 60px;
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.iconError {
    border-radius: 50%;
    border: 2px solid red;
    height: 60px;
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
}

.overFlowMessage{
    height: 50px !important;
    overflow: auto !important;
    display: block !important;
}

.actionButton {
    display: flex;
    flex-direction: row;;
    justify-content: center;
}

.actionButtonError > button{
    margin-right: 10px;
    width: 125px;
}

.actionButton > button{
    width: 125px;
}

.commentAlert{
    font-size: 16px;
    margin: 10% 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
}